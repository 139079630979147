
<template >
  <div class="task">
    <div class="task-detail">
      <div style="display: flex;justify-content: space-between;margin: 0px 0.2rem;align-items: center;padding: 10px 0"
           ref="scrollNews">
        <div class="task-title bold">{{infoList.name}}</div>
        <!-- <div class="flex">
          <div v-if="infoList.hasFinished"
               class="task-status-complete">{{infoList.hasFinished ? '已完成' : '未完成'}}</div>
          <div v-if="!infoList.hasFinished"
               class="task-status-pending">{{infoList.hasFinished ? '已完成' : '未完成'}}</div>
        </div> -->
      </div>
      <div class="task-bottom-detail">
        <div v-for="(taskItem,infoIndex) in infoList.child" :key="infoIndex" style="margin-top: 20px;">
          <div class="flex align_items_center justify_content_space_between">
            <div class="flex align_items_center" @click="taskItem.hasFinished = taskItem.taskType == 'radio' ? !taskItem.hasFinished : taskItem.hasFinished ">
              <van-icon :name="taskItem.hasFinished ? 'checked':'circle'" size="0.4rem" :color="taskItem.hasFinished ? '#3F7C53':'#333333'" />
              <div class="task-cell-taskName bold">{{taskItem.taskName}}</div>
            </div>
            <van-radio-group @change="vanVadioChange" v-model="taskItem.taskValue " v-if="taskItem.hasChild && taskItem.taskType=='alter_bool'" direction="horizontal">
              <van-radio v-for="(item,index) in taskItem.child" :key="index" :name="item.id.toString()">{{['是','否'][index]}}
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
                </template>
              </van-radio>
            </van-radio-group>
          </div>

          <div v-if="taskItem.taskType == 'input_text' || taskItem.taskType == 'equip_text'" class="task-input">
            <van-field v-model="taskItem.taskValue" :placeholder="taskItem.taskDesc" @blur="textFieldBlur(taskItem.id)" :maxlength="taskItem.inputCount" style="background-color: white"></van-field>
          </div>

          <ul v-if="taskItem.taskType=='text'" class="task-text">
            <li v-for="(item,index) in taskItem.taskValue.split(';')" :key="index" style="margin: 6px 0">{{item}} </li>
          </ul>

          <div v-if="taskItem.taskType == 'pic'" class="task-pic">
            <div class="width_full flex">
              <img :src="siteimg" class="site-img" v-if="taskItem.taskCode == 'storefrontSignboard'">
              <img :src="sitezzimg" class="site-img" v-if="taskItem.taskCode == 'masterImg'">
              <van-uploader ref="bankImgup"
                            :max-count="taskItem.inputCount"
                            v-model="taskItem[taskItem.taskCode]"
                            :after-read="afterRead"
                            :before-delete="deleteUploadPic"
                            :name="taskItem.id"
                            upload-text=""
                            :accept="(taskItem.taskCode == 'masterImg' || taskItem.taskCode == 'storefrontSignboard' )&& androidFlag ? 'image/*,.png' : 'image/*'"
                            style="margin-top: 10px;"
                            :class="{'.before': taskItem.taskCode == 'masterImg' || taskItem.taskCode == 'storefrontSignboard'}"/>
                            <!-- 站长人像、门头， -->
            </div>
          </div>
          <div v-if="taskItem.taskType != 'input_text' && taskItem.taskType != 'equip_text' && !taskItem.hasChild "
               class="task-taskDesc">{{taskItem.taskDesc}}
          </div>

          <!-- 任务里面包含子任务-->
          <div v-if="taskItem.hasChild" style="">
            <div v-for="(taskChildItem,infoIndex) in taskItem.child" :key="infoIndex" style="margin-top: 20px;">
              <div v-if="taskChildItem.id == taskItem.taskValue">
                <div class="task-cell-child-taskName bold">{{taskChildItem.taskName}}</div>
                <div v-if="taskChildItem.taskType == 'input_text' || taskItem.taskType == 'equip_text'" class="task-input">
                  <van-field v-model="taskChildItem.taskValue" @blur="textFieldBlur(taskItem.id)" :placeholder="taskChildItem.taskDesc" :maxlength="taskChildItem.inputCount" type="textarea" style="background-color: white"></van-field>
                </div>
                <ul v-if="taskItem.taskType=='text'" class="task-text">
                  <li style="margin: 6px 0;" v-for="(item,index) in taskChildItem.list" :key="index">{{item}}</li>
                </ul>
                <div v-if="taskChildItem.taskType == 'pic'" class="task-pic">
                  <div class="width_full" style="display: inline-block;">
                    <van-uploader ref="bankImgup" :max-count="taskChildItem.inputCount"
                                  v-model="taskChildItem[taskChildItem.taskCode]"
                                  :after-read="afterRead"
                                  :before-delete="deleteUploadPic"
                                  :name="taskChildItem.parentTaskId"
                                  upload-text=""
                                  style="margin-top: 10px" />
                  </div>
                </div>
                <div v-if="taskChildItem.taskType != 'input_text' && taskChildItem.taskType != 'equip_text'" class="task-taskDesc">{{taskChildItem.taskDesc}}</div>
              </div>

              <div v-if="taskItem.taskType=='subClass'" style="position: relative;">
                <div class="task-cell-child-taskName bold" v-if="taskChildItem.taskType != 'invertRedio'" >{{taskChildItem.taskName}}</div>
                <div v-if="taskChildItem.taskType == 'input_text' || taskItem.taskType == 'equip_text'" class="task-input">
                  <van-field v-model="taskChildItem.taskValue" @blur="textFieldBlur(taskItem.id)" :placeholder="taskChildItem.taskDesc"
                             :maxlength="taskChildItem.inputCount" style="background-color: white"></van-field>
                </div>
                <ul v-if="taskItem.taskType=='text'" class="task-text">
                  <li style="margin: 6px 0;" v-for="(item,index) in taskChildItem.list" :key="index">{{item}}</li>
                </ul>
                <div v-if="taskChildItem.taskType == 'pic'" class="task-pic">
                  <div class="width_full" style="display: inline-block;">
                    <van-uploader ref="bankImgup" :max-count="taskChildItem.inputCount"
                                  v-model="taskChildItem[taskChildItem.taskCode]"
                                  :after-read="afterRead"
                                  :before-delete="deleteUploadPic"
                                  :name="taskChildItem.parentTaskId"
                                  upload-text=""
                                  style="margin-top: 10px" />
                  </div>
                </div>
                <div v-if="taskChildItem.taskCode === 'moneyCountMachine_charge'"  style="padding-left:0.5rem; font-size:0.3rem">
                  <van-checkbox v-model="moneyCountMachineChecked" icon-size="0.35rem" checked-color="#3F7C53">{{taskChildItem.taskName}}</van-checkbox>
                </div>
                 <div v-if="taskChildItem.taskCode === 'moneyCountMachine_charge'" class="flex charge-wrap">
                  <div>选择免收原因</div>
                  <van-button plain size="mini" :disabled="m.disabled" :class="{'chargeActived':m.active}"
                              v-for="(m, n) in moneyCountMachineList" :key="n" @click="moneyCountMachineClick(n)">{{m.label}}</van-button>
                </div>
                <div v-if="taskChildItem.taskCode === 'safeBox_charge'"  style="padding-left:0.5rem; font-size:0.3rem">
                  <van-checkbox v-model="safeBoxChecked" icon-size="0.35rem" checked-color="#3F7C53">{{taskChildItem.taskName}}</van-checkbox>
                </div>
                <div v-if="taskChildItem.taskCode === 'safeBox_charge'" class="flex charge-wrap">
                  <div>选择免收原因</div>
                  <van-button plain size="mini" :disabled="m.disabled" :class="{'chargeActived':m.active}"
                              v-for="(m, n) in chargeList" :key="n" @click="chargeClick(n)">{{m.label}}</van-button>
                </div>
                <div v-if="taskChildItem.taskType !== 'input_text' && taskChildItem.taskType !== 'equip_text' && taskChildItem.taskType !== 'invertRedio'"
                     class="task-taskDesc">{{taskChildItem.taskDesc}}</div>

                <div v-if="taskChildItem.taskCode === 'monitorEquipment_validateCode'" class="flex"
                     style="margin-left:0.5rem; font-size:0.3rem; color:#333; align-items:center">
                  <van-icon name="checked" size="0.4rem" color="#3F7C53" /> <span style="margin-left:0.1rem">收取设备押金</span>
                </div>

                <div v-if="taskChildItem.taskCode === 'terminalPOS_charge' && infoList.needPOSDeposit " class="flex"
                     style="margin-left:0.5rem; font-size:0.3rem; color:#333; align-items:center">
                  <van-checkbox v-model="safePOSChecked" icon-size="0.35rem" checked-color="#3F7C53">{{taskChildItem.taskName}}</van-checkbox>
                </div>
                <div v-if="taskChildItem.taskCode === 'terminalPOS_charge' && infoList.needPOSDeposit " class="flex charge-wrap">
                  <div>选择免收原因</div>
                  <van-button plain size="mini" :disabled="m.disabled" :class="{'chargeActived':m.active}"
                              v-for="(m, n) in childList" :key="n" @click="chargeClick1(n)">{{m.label}}</van-button>
                </div>


              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="footer flex justify_content_space_between">
      <van-button slot="button" style="border-radius: 4px;font-size: 18px;background-color: #3F7C53;color: #FFFFFF;border: solid #FFFFFF 0; width:100%" color="#3F7C53" v-if="this.$route.query.detail != '1'" @click="toNextStep()">确认</van-button>
    </div>

    <van-overlay :show="clipshow">
      <clipPic :imgSrc="imgSrc" @clipClick="clipClick"></clipPic>
    </van-overlay>

  </div>
</template>

<script>
import common from "@/utils/common";
import { saveMyTask, uploadZipFile, bindEquipment, getSubTaskDetail } from "@/utils/api";
import JsZip from 'jszip'
import store from "@/store";
import {appAction, uwStatisticAction} from "../../../utils/util";
import clipPic from '@/components/clipPic'
export default {
  name: "standardizationTask",
  watch: {
    infoList (newValue) {
      this.toShowPreview(newValue.child)
      this.bigImageCount = 0
      this.toSetScrollTop()
    },
    moneyCountMachineChecked(val){
      for(let obj of this.infoList.child){
        if(obj.hasChild){
          for(let m of obj.child){
            if(m.taskCode == 'moneyCountMachine_charge'){
              m.taskValue = val? '1' : '0'
              if(val){
                m.taskDesc = ''
              }
            }
          }
        }
      }
       if(val){
        for(let obj of this.moneyCountMachineList){
          obj.disabled = true
          obj.active = false
        }
      }else{
        for(let obj of this.moneyCountMachineList){
          obj.disabled = false
        }
      }
    },
    safeBoxChecked(val){
      for(let obj of this.infoList.child){
        if(obj.hasChild){
          for(let m of obj.child){
            if(m.taskCode == 'safeBox_charge'){
              m.taskValue = val? '1' : '0'
              if(val){
                m.taskDesc = ''
              }
            }
          }
        }
      }
      if(val){
        for(let obj of this.chargeList){
          obj.disabled = true
          obj.active = false
        }
      }else{
        for(let obj of this.chargeList){
          obj.disabled = false
        }
      }
    },
    safePOSChecked(val){
      for(let obj of this.infoList.child){
        if(obj.hasChild){
          for(let m of obj.child){
            if(m.taskCode === 'terminalPOS_charge'){
              m.taskValue = val? '1' : '0'
              if(val){
                m.taskDesc = ''
              }
            }
          }
        }
      }
      if(val){
        for(let obj of this.childList){
          obj.disabled = true
          obj.active = false
        }
      }else{
        for(let obj of this.childList){
          obj.disabled = false
        }
      }
    },
  },
  components: {clipPic},
  deactivated () {
    window.removeEventListener('scroll', this.handleScroll, true);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    moneyCountMachineClick(index){
      for(let obj of this.moneyCountMachineList){
        obj.active = false
      }
      for(let obj of this.moneyCountMachineList){
        this.moneyCountMachineList[index].active = true
      }
      for(let obj of this.infoList.child){
        if(obj.hasChild){
          for(let m of obj.child){
            if(m.taskCode == 'moneyCountMachine_charge' && m.taskValue == '0'){
              m.taskDesc = this.moneyCountMachineList[index].label
            }
          }
        }
      }
    },
    chargeClick(index){
      for(let obj of this.chargeList){
        obj.active = false
      }
      for(let obj of this.chargeList){
        this.chargeList[index].active = true
      }
      for(let obj of this.infoList.child){
        if(obj.hasChild){
          for(let m of obj.child){
            if(m.taskCode == 'safeBox_charge' && m.taskValue == '0'){
              m.taskDesc = this.chargeList[index].label
            }
          }
        }
      }
    },
    chargeClick1(index){
      for(let obj1 of this.childList){
        obj1.active = false
      }
      for(let obj1 of this.childList){
        this.childList[index].active = true
      }
      for(let obj1 of this.infoList.child){
        if(obj1.hasChild){
          for(let m of obj1.child){
            if(m.taskCode === 'terminalPOS_charge' && m.taskValue === '0'){
              m.taskDesc = this.childList[index].label
            }
          }
        }
      }
    },
    handleScroll (scrollerTarget) {
      this.layout = scrollerTarget.target
    },
    // 让页面划到顶部
    toSetScrollTop () {
      if (this.layout) {
        this.layout.scrollTo(0, 0)
      }

    },
    // 单选框的值改变
    vanVadioChange () {
      this.toRefreshTaskStatus(this.infoList.child)
    },
    // 输入框失去焦点
    textFieldBlur (id) {
      this.toRefreshTaskStatus(this.infoList.child, 0 , false, id)
    },
    // 回显图片预览
    toShowPreview (arrayInfo) {
      var baseUrl = process.env.NODE_ENV != 'production' ? 'http://124.70.167.116:20081' + '/file/' : window.location.origin + '/file/'
      var tokenUrl = '?Authorization=' + common.getItem('wtToken')
      for (var taskItem of arrayInfo) {
        if (taskItem.taskType == 'pic' && taskItem.taskValue != '' && taskItem.taskValue != undefined && taskItem.taskValue.length > 0) {
          taskItem[taskItem.taskCode] = []
          for (var imageUrl of taskItem.taskValue) {
            taskItem[taskItem.taskCode].push({ url: baseUrl + imageUrl + tokenUrl })
          }
        }
        if (taskItem.hasChild) {
          this.toShowPreview(taskItem.child)
        }
      }
    },
    // 去下一步
    toNextStep () {
      // 去压缩图片
      for(let obj of this.infoList.child){
        if(obj.hasChild){
          for(let m of obj.child){
            if(obj.taskCode === "moneyCountMachine_equip" &&  obj.hasFinished){
              if(m.taskCode === 'moneyCountMachine_charge' && m.taskValue === '0'){
                if(!m.taskDesc){
                  this.$toast('请选择点钞机免收押金原因')
                  return
                }
              }
            }
            if(obj.taskCode === "safeBox_equip" &&  obj.hasFinished){
              if(m.taskCode === 'safeBox_charge' && m.taskValue === '0'){
                if(!m.taskDesc){
                  this.$toast('请选择保险柜免收押金原因')
                  return
                }
              }
            }
          }
        }
      }
      this.toAssembleImageCompressionPackage(this.infoList.child)
    },
    // 上传图片压缩包
    toUploadImageCompressionPackage (uploadFile, stationNo) {
      uploadZipFile(uploadFile, stationNo).then(res => {
        // 加载状态结束
        this.loading = false;
        if (res.code == 200) {
          if (res.data.businessCode && res.data.businessCode === 200) {
            this.uploadZipUrlData = res.data
            console.log('this.uploadZipUrlData:', this.uploadZipUrlData)
            this.submit()
          }
        }
      }).catch(() => {
      })
    },
    // 组装数据
    toAssemblyData (arrayInfo) {
      var baseUrl = process.env.NODE_ENV != 'production' ? 'http://124.70.167.116:20081' + '/file/' : window.location.origin + '/file/'
      var tokenUrl = '?Authorization=' + common.getItem('wtToken')
      for (var taskItem of arrayInfo) {
        if (taskItem.taskType == 'pic' && taskItem.hasFinished) {
          var imgValue = [];
          for (var imgObj of taskItem[taskItem.taskCode]) {
            if (imgObj.url != undefined) {
              if (imgObj.url.indexOf(baseUrl) != -1) {
                imgValue.push((imgObj.url.split(baseUrl).pop()).split(tokenUrl)[0])
              } else {
                imgValue.push(imgObj.url)
              }
            } else {
              imgValue.push(this.uploadZipUrlData[taskItem.taskCode][0])
              this.uploadZipUrlData[taskItem.taskCode].shift();
            }
          }
          taskItem.taskValue = imgValue
        }
        if (taskItem.hasChild) {
          this.toAssemblyData(taskItem.child)
        }
      }
    },
    // 删除数据中携带的图片原信息
    toDeleteImageData (arrayInfo) {
      for (var taskItem of arrayInfo) {
        if (taskItem.taskType == 'pic') {
          taskItem[taskItem.taskCode] = []
        }
        if (taskItem.hasChild) {
          this.toDeleteImageData(taskItem.child)
        }
      }
    },
    // 获取设备绑定信息
    toGetBindEquipmentStr(arrayInfo){
        for (var taskItem of arrayInfo){
            if (taskItem.taskType == 'equip_text'){
                this.equipmentStr[taskItem.taskCode] = taskItem.taskValue
            }
            if (taskItem.hasChild){
                this.toGetBindEquipmentStr(taskItem.child)
            }
        }
    },
    // 绑定设备
    toBindEquipment(){
        this.equipmentStr = {}
        this.toGetBindEquipmentStr(this.infoList.child)
        if (JSON.stringify(this.equipmentStr) == '{}'){
            this.toAssembleImageCompressionPackage(this.taskContent[this.showStep].child)
            return
        }
        bindEquipment({
            'stationNo':this.taskInfo.stationNo,
            'loginUser':common.getItem('uniqueNo'),
            'equipmentStr':JSON.stringify(this.equipmentStr),
        }).then(res=>{
            // 加载状态结束
            this.loading = false;
            if (res.code == 200){
                if (res.data.businessCode && res.data.businessCode === 200) {
                    this.toAssembleImageCompressionPackage(this.taskContent[this.showStep].child)
                }
            }
        }).catch(()=>{
        })
    },
    // 保存任务信息
    toSaveMyTask () {
      this.toAssemblyData(this.infoList.child)
      this.uploadData = JSON.parse(JSON.stringify((this.taskContent[this.showStep])))
      this.toDeleteImageData(this.uploadData.child)
      saveMyTask({
        'taskNo': this.taskInfo.taskNo,
        'taskCode': this.taskInfo.taskCode,
        'currStep': this.showStep,
        'stepContent': JSON.stringify(this.uploadData),
        'hasSubmit': this.toGetNextStep() == undefined
      }).then(res => {
        // 加载状态结束
        this.loading = false;
        if (res.code == 200) {
          if (res.data.businessCode && res.data.businessCode === 200) {
            if (this.toGetNextStep() != undefined) {
              this.showStep = this.toGetNextStep()
              this.taskContent = JSON.parse(res.data.taskContent);
              console.log("this.taskContent:", this.taskContent)
              this.infoList = this.taskContent[this.showStep]
            } else {
              this.$router.back()
            }

          }
        }
      }).catch(() => {
      })
    },
    // 图片读取后
    afterRead (file, detail) {
      console.log('file:', file)
      console.log('detail:', detail)
      // 大于15M
      if (file.file.size > 15 * 1024 * 1024) {
        Toast('图片太大，请上传小于15M的图片')
        return
      }

      if (!common.imageVerify(file.file.type)) {
        Toast('上传图片必须为png或者jpg格式')
        return
      }
      // 大于100k需要压缩
      if (file.file.size >= 100 * 1024) {
        this.bigImageCount++
      }

      if(common.getItem('androidNotClip') && (detail.name == 'masterImg' || detail.name == 'storefrontSignboard')){
        this.clipshow = true
        this.masterImgFile = file
        this.imgSrc = file.content
        return
      }
      this.toRefreshTaskStatus(this.infoList.child, 0 , false, detail.name)
    },
    clipClick(data){
      this.clipshow = false
      for(let obj of this.infoList.child){
        if(obj.taskCode == 'masterImg'){
          obj['masterImg'] = [{content: data, file: this.masterImgFile.file, message: '', status:''}]
        }
        if(obj.taskCode == 'storefrontSignboard'){
          obj['storefrontSignboard'] = [{content: data, file: this.masterImgFile.file, message: '', status:''}]
        }
      }
      this.toRefreshTaskStatus(this.infoList.child)
    },
    // 删除预览图片后
    deleteUploadPic (file, detail) {
      console.log("index:", detail)
      if (file.url == undefined && file.file.size >= 100 * 1024) {
        this.bigImageCount--
      }
      setTimeout(() => {
        this.toRefreshTaskStatus(this.infoList.child, detail.index, true)
      }, 100)
      return true
    },
    // 组装压缩图片数据
    toAssemblingCompressedImageData (dataURL, name, imgKey) {
      for (var i = 0; i < this.uploadPicList.length; i++) {
        if (this.uploadPicList[i].imgKey == imgKey) {
          this.uploadPicList[i].name = name;
          this.uploadPicList[i].dataURL = dataURL;
          return;
        }
      }
      this.uploadPicList.push({ name: name, imgKey: imgKey, dataURL: dataURL })
    },
    // 压缩图片
    toCompressPicture (file, detail) {
      var imageFileName = detail.name + '.' + file.file.name.split('.').pop()
      // 小于100K不进行压缩
      if (file.file.size < 100 * 1024) {
        this.toAssemblingCompressedImageData(file.content, imageFileName, detail.name)
        return
      }
      let reader = new FileReader()
      reader.readAsDataURL(file.file)
      let _this = this
      reader.onload = function (e) {
        let content = file.content //图片的src，base64格式
        let img = new Image()
        img.src = content
        _this.perviewImage = content;
        img.onload = function () {
          common.compress(img, (dataURL) => {
            _this.toAssemblingCompressedImageData(dataURL, imageFileName, detail.name)
          })
        }
      }
    },
    // 刷新任务状态
    toRefreshTaskStatus (arrayInfo, index, str, id) {
      for (var taskItem of arrayInfo) {
        if (taskItem.taskType == 'pic') {
          console.log("taskItem:", taskItem)
          if (taskItem[taskItem.taskCode] != undefined && taskItem[taskItem.taskCode].length >= taskItem.minInputCount) {
            taskItem.hasFinished = true
          } else {
            taskItem.hasFinished = false
          }
          if (str && taskItem['taskValue']) {
            taskItem['taskValue'].splice(index, 1)
          }
        } else if (taskItem.taskType == 'input_text') {
          if (taskItem.taskValue != undefined && taskItem.taskValue.length >= taskItem.minInputCount) {
            taskItem.hasFinished = true
          } else {
            taskItem.hasFinished = false
          }
        } else if (taskItem.taskType == 'equip_text') {
          if (taskItem.taskValue != undefined && taskItem.taskValue.length >= taskItem.minInputCount) {
            taskItem.hasFinished = true
          } else {
            taskItem.hasFinished = false
          }
        }

        if (taskItem.hasChild) {
          for (var taskItemChild of taskItem.child) {
            if (taskItemChild.id == taskItem.taskValue) {
              if (taskItemChild.taskType == 'pic') {
                if (taskItemChild[taskItemChild.taskCode] != undefined && taskItemChild[taskItemChild.taskCode].length >= taskItemChild.minInputCount) {
                  taskItem.hasFinished = true
                  taskItemChild.hasFinished = true
                } else {
                  taskItem.hasFinished = false
                  taskItemChild.hasFinished = false
                }
                if (str && taskItemChild['taskValue']) {
                  taskItemChild['taskValue'].splice(index, 1)
                }
              } else if (taskItemChild.taskType == 'input_text') {
                if (taskItemChild.taskValue != undefined && taskItemChild.taskValue.length >= taskItemChild.minInputCount) {
                  taskItem.hasFinished = true
                  taskItemChild.hasFinished = true
                } else {
                  taskItem.hasFinished = false
                  taskItemChild.hasFinished = false
                }
              } else if (taskItemChild.taskType == 'equip_text') {
                if (taskItemChild.taskValue != undefined && taskItemChild.taskValue.length >= taskItemChild.minInputCount) {
                  taskItem.hasFinished = true
                  taskItemChild.hasFinished = true
                } else {
                  taskItem.hasFinished = false
                  taskItemChild.hasFinished = false
                }
              }
            } else if(taskItem.taskType=='subClass'){
              if (taskItemChild.taskType == 'pic') {
                if (taskItemChild[taskItemChild.taskCode] != undefined && taskItemChild[taskItemChild.taskCode].length >= taskItemChild.minInputCount) {
                  // taskItem.hasFinished = true
                  taskItemChild.hasFinished = true
                } else {
                  // taskItem.hasFinished = false
                  taskItemChild.hasFinished = false
                }
                if (str && taskItemChild['taskValue']) {
                  taskItemChild['taskValue'].splice(index, 1)
                }
              } else if (taskItemChild.taskType == 'input_text') {
                if (taskItemChild.taskValue != undefined && taskItemChild.taskValue.length >= taskItemChild.minInputCount) {
                  // taskItem.hasFinished = true
                  taskItemChild.hasFinished = true
                } else {
                  // taskItem.hasFinished = false
                  taskItemChild.hasFinished = false
                }
              }
            } else {
              taskItemChild.hasFinished = false
            }
          }

        }
      }

      for(let obj of arrayInfo){
        if(obj.hasChild && obj.taskType=='subClass' && id == obj.id){
          let flag = undefined
          for(let m of obj.child){
            if(m.minInputCount >= 1){
              if(m.hasFinished ){
                flag = true
              }else{
                flag = false
                break
              }
            }
            else if(m.minInputCount == 0 ){
              flag = true
            }
          }
          if(flag){
            obj.hasFinished = true
          }else{
            obj.hasFinished = false
          }
        }
      }
      common.setItem('androidNotClip', true)
    },
    // 组装图片列表
    toAssemblyPicturelList (arrayInfo) {
      for (var taskItem of arrayInfo) {
        if (taskItem.taskType == 'pic' && taskItem.hasFinished) {
          if (taskItem[taskItem.taskCode] != undefined && taskItem[taskItem.taskCode].length > 0 && taskItem[taskItem.taskCode].length >= taskItem.minInputCount) {
            for (var i = 0; i < taskItem[taskItem.taskCode].length; i++) {
              if (taskItem[taskItem.taskCode][i].url != undefined) {
                continue;
              }
              this.toCompressPicture(taskItem[taskItem.taskCode][i], { name: taskItem.taskCode + '_' + (i.toString()) })
            }
          }
        }
        if (taskItem.hasChild) {
          this.toAssemblyPicturelList(taskItem.child)
        }
      }
    },
    // 检查输入项是否完成
    toCheckInputInformation (arrayInfo) {
      for (var taskItem of arrayInfo) {
        if (!taskItem.hasFinished) {
          Toast('请按要求完成' + taskItem.taskName)
          return false;
        }
      }
      return true;
    },
    toCheckPreStep () {//检查前4步是否完成
      console.log("this.taskContent", this.taskContent)
      let isFinished = true
      for (var stepItem of this.taskContent['stepList'])
        console.log("stepItem:", stepItem)

      this.taskContent['stepList'].forEach((stepItem, index) => {
        if ((!this.taskContent[stepItem].hasFinished) && (index !== this.taskContent['stepList'].length - 1)) {
          Toast('请按要求完成所有任务')
          isFinished = false
        }

      })
      if (isFinished) {
        return this.toCheckInputInformation(this.infoList.child)
      } else {
        return false
      }

    },
    // 组装图片压缩包
    toAssembleImageCompressionPackage (arrayInfo) {
      this.uploadPicList = []
      this.toAssemblyPicturelList(arrayInfo)
      store.commit('loading/CHANGE_LOADING', true)
      setTimeout(() => {
        store.commit('loading/CHANGE_LOADING', false)
        if (this.uploadPicList && this.uploadPicList.length > 0) {
          var zip = new JsZip();
          var img = zip.folder("images");
          for (var i = 0; i < this.uploadPicList.length; i++) {
            img.file(this.uploadPicList[i].name, this.uploadPicList[i].dataURL.substring(this.uploadPicList[i].dataURL.indexOf(",") + 1), { base64: true });
          }
          var self = this
          zip.generateAsync({ type: "blob" }).then(function (content) {
            self.toUploadImageCompressionPackage(content, self.$route.query.stationNo)
            // 用浏览器下载图片压缩包
            // let url = window.URL.createObjectURL(new Blob([content])) //创建下载链接
            // let link = document.createElement('a') //创建a标签
            // link.style.display = 'none'  //将a标签隐藏
            // link.href = url  //给a标签添加下载链接
            // link.setAttribute('download', "example.zip") // 此处注意，要给a标签添加一个download属性，属性值就是文件名称 否则下载出来的文件是没有属性的，空白白
            // document.body.appendChild(link)
            // link.click()  //执行a标签
          }, e => {
            console.log('error', e)
          });
        } else {
          this.submit()
        }

      }, this.bigImageCount * 1500)

    },
    togetSubTaskDetail () {
      getSubTaskDetail({ 'taskNo': this.$route.query.taskNo, 'subTaskCode': this.$route.query.code }).then(res => {
        if (res.code == 200) {
          if (res.data && res.data.businessCode == 200) {
            this.infoList = res.data.data
            for(let obj of this.infoList.child){
              if(obj.hasChild){
                for(let m of obj.child){
                  if(m.taskCode == 'moneyCountMachine_charge' && m.taskValue){
                    this.moneyCountMachineChecked = m.taskValue == '1' ? true : false
                    if(!this.moneyCountMachineChecked && m.taskDesc){
                      for(let [i,j] of this.moneyCountMachineList.entries()){
                        if(j.label == m.taskDesc){
                          this.moneyCountMachineList[i].active = true
                        }
                      }
                    }

                  }
                  if(m.taskCode == 'safeBox_charge' && m.taskValue){
                    this.safeBoxChecked = m.taskValue == '1' ? true : false
                    if(!this.safeBoxChecked && m.taskDesc){
                      for(let [i,j] of this.chargeList.entries()){
                        if(j.label == m.taskDesc){
                          this.chargeList[i].active = true
                        }
                      }
                    }
                  }
                  if(m.taskCode === 'terminalPOS_charge' && m.taskValue){
                    this.safePOSChecked = m.taskValue === '1' ? true : false
                    if(!this.safePOSChecked && m.taskDesc){
                      for(let [i,j] of this.childList.entries()){
                        if(j.label === m.taskDesc){
                          this.childList[i].active = true
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      })
    },
    submit () {
      this.toAssemblyData(this.infoList.child)
      this.uploadData = JSON.parse(JSON.stringify(this.infoList))
      console.log('uploadData:',this.uploadData)
      this.toDeleteImageData(this.uploadData.child)
      saveMyTask({
        'taskNo': this.$route.query.taskNo,
        'currStep': this.$route.query.code,
        'stepContent': JSON.stringify(this.uploadData),
        'hasSubmit': false
      }).then(res => {
        if (res.code == 200) {
          if (res.data.businessCode && res.data.businessCode === 200) {
            this.$router.push({ name: 'standardTaskList', query: { taskNo: this.$route.query.taskNo, stationNo: this.$route.query.stationNo } })
          }
        }else{
          this.$toast(res.data.businessMessage)
        }
      })
    },
  },
  data () {
    return {
      uploadPicList: [],
      showStep: '',
      logoImg: require('@/assets/img/siteManage/headerIcon.png'),
      taskInfo: {},
      infoList: {
      },
      uploadZipUrlData: {},
      bigImageCount: 0,
      uploadData: {},
      equipmentStr: {},
      layout: null,
      activeIcon: require('@/assets/img/task/task_yes.png'),
      inactiveIcon: require('@/assets/img/task/task_no.png'),
      siteimg: require('@/assets/img/task/site.png'),
      sitezzimg: require('@/assets/img/task/sitezz.png'),
      clipshow: false,
      imgSrc: '',
      masterImgFile:null,
      androidFlag: common.getItem('jumpFrom') === 'android' ? true : false,
      safeBoxChecked: false,
      moneyCountMachineChecked: false,
      chargeList:[
       {label: '行方配置', active: false, disabled: false},{label: '站点自有', active: false, disabled: false}
      ],
      moneyCountMachineList: [
        {label: '行方配置', active: false, disabled: false},{label: '站点自有', active: false, disabled: false}
      ],
      safePOSChecked:false,
      childList:[
        {label: '行方配置', active: false, disabled: false},{label: '站点自有', active: false, disabled: false}
      ],
    }

  },
  mounted () {
    this.togetSubTaskDetail()
    window.addEventListener("scroll", this.handleScroll, true);
    uwStatisticAction('/task/standardizationTask','站点标准化输入')
  }

}
</script>

<style lang="less" scoped>
.task {
  background-color: #f7f8f9;
  min-height: 100%;
  width: 100%;
  .van-overlay{
    z-index: 1005;
  }
  .img-icon {
    height: 20px;
  }
  .flex {
    display: flex;
  }
  .site-img{
    width: 1.04rem;
    height: 1.04rem;
    margin-top: 10px;
    margin-right: 10px;
  }
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    // height: 0.88rem;
    padding: 0.2rem;
    background-color: #f7f8f9;
    // margin-bottom: 0.2rem;
    z-index: 1001;
  }
  .van-uploader {
    position: relative;
    display: inline-block;
    vertical-align: text-bottom;
  }
  .charge-wrap{
    padding-left:0.3rem;
    font-size:0.26rem;
     margin-top:0.2rem;
     align-items:center;
    div{
      margin-right: 0.1rem;
    }
    .van-button{
      color: #4A4A4A;
      font-size: 0.28rem;
      background: rgba(204, 204, 204, 0.1);
      border: 1px solid rgba(204, 204, 204, 0.3);
      margin-right: 0.1rem;
      text-align: center;
    }
  }
  .task-detail {
    padding: 0.2rem;
    background-color: #f7f8f9;
    padding-bottom: 50px;
    .task-status-complete {
      background-color: #e0f5eb;
      font-size: 12px;
      color: #56cb8f;
      padding: 3px 5px;
      border-radius: 2px;
    }
    .task-status-pending {
      background-color: rgba(153, 153, 153, 0.1);
      font-size: 12px;
      color: #999999;
      padding: 3px 5px;
      border-radius: 2px;
    }
    .task-title {
      font-size: 17px;
      color: #333333;
    }
    div.task-title:before {
      width: 10px;
      height: 10px;
      color: #3f7c53;
      content: "| ";
    }
    .task-bottom-detail {
      padding: 0 0.2rem 50px 0.2rem;
    }
    .task-cell-taskName {
      margin-left: 10px;
      font-size: 16px;
      color: #272829;
    }
    .task-cell-child-taskName {
      margin-left: 30px;
      font-size: 16px;
      color: #272829;
    }
    .task-input {
      background-color: #eeeeee;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .task-text {
      margin-left: 0.56rem;
      font-size: 13px;
      color: #999999;
    }
    .task-pic {
      margin-left: 0.5rem;
      margin-bottom: 10px;
      margin-right: 0.2rem;
    }
    .task-taskDesc {
      font-size: 12px;
      color: #999999;
      margin-left: 0.5rem;
      margin-right: 0.3rem;
      margin-top: 5px;
    }
  }
}
</style>
<style lang="less">
.task .van-uploader__preview {
  margin-right: 15px !important;
}
.task .van-uploader__upload,
.task .van-uploader__preview-image {
  width: 1.04rem !important;
  height: 1.04rem !important;
  border-radius: 0.08rem;
}
.task{
  .task-detail{
    .charge-wrap{
      .chargeActived{
        color: #3F7C53;
        border: 1px solid rgba(63, 124, 83, 0.3);
      }
    }
  }
}
</style>
