<template>
  <div class="clip-pic">
      <div class="container">
        <div class="img-container">
          <img :src="imgSrc" ref="image" alt=""> 
        </div>
        <div class="btn-wrap">
          <el-button @click="rotateBtn">旋转</el-button>
          <el-button @click="sureSava">裁剪</el-button>
        </div>
      </div>
      
    </div>
</template>

<script>
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import common from '@/utils/common'
export default {
  data() {
    return {
      afterImg: '',
      myCropper: null,
    }
  },
  props:['imgSrc'],
  watch:{
    imgSrc(val){
      this.myCropper.replace(val)
    }
  },
  mounted(){
    this.init();
  },
  methods:{
    init(){
      this.myCropper = new Cropper(this.$refs.image, {
        viewMode: 1,
        aspectRatio: 1/1,//默认比例
        dragMode: 'move',
        // cropBoxResizable :false, //是否允许拖动 改变裁剪框大小
        cropBoxMovable :false, //是否允许拖动裁剪框
        aspectRatio: 1, //裁剪框比例 1：1
        resizable: true,  //是否允许改变裁剪框的大小
        //  guides :false,//裁剪框虚线 默认true有
        preview: '.before',
        background: false,
        autoCropArea: 1, // 裁剪大小倍数
      })
      
    },
    sureSava(){
      this.afterImg = this.myCropper.getCroppedCanvas({
        imageSmoothingQuality: 'high'
      }).toDataURL('image/jpeg')
      this.$emit('clipClick', this.afterImg )
    },
    rotateBtn() {
      this.myCropper.rotate('90')
    },
  }
}
</script>

<style lang="less" scoped>
.clip-pic{
  background: #000;
  height: 100%;
  img{
    width:100%;
  }
  .container{
    padding-top: 1rem;
  }
  .btn-wrap{
    text-align: center;
  }
  .el-button{
    margin: 0.5rem;
  }
}
</style>